<template>
  <MainLayout>
    <Toolbar slot="toolbar" @back="onBackClick">Отчет</Toolbar>
    <transition name="fade">
      <Loader v-if="monthsFirstFetch" class="transition duration-50" />
      <p v-else-if="months.length === 0" class="text-center">Упс, пусто</p>
      <div v-else class="transition duration-300">
        <!--<Info v-if="monthsInfo.length !== 0" :html="monthsInfo" />-->
        <Info :html="plug" />
        <div class="p-4">
          <UiInfiniteScroll @callback="fetchMonthsForScroll">
            <Month
              v-for="v in months"
              :key="`month_${v.dateNumber}`"
              :month="v"
              class="mb-4"
              @click="onMonthClick(v)"
            />
          </UiInfiniteScroll>
        </div>
      </div>
    </transition>
  </MainLayout>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import MainLayout from '@layout/MainLayout.vue';
  import Toolbar from '@layout/Toolbar.vue';
  import Loader from '@ui/Loader.vue';
  import UiInfiniteScroll from '@ui/UiInfiniteScroll';

  import { RyadomApp } from '@shared/RyadomApp';

  import Info from './components/Info.vue';
  import Month from './components/Month.vue';

  export default {
    name: 'Main',
    components: {
      MainLayout,
      Toolbar,
      Info,
      Month,
      Loader,
      UiInfiniteScroll
    },
    inject: ['toast'],
    data() {
      return {
        plug: '<p class="text-white">На забудьте сдать дополнительные часы и данные по реферальной программе</p>'
      };
    },
    computed: {
      ...mapGetters([
        'months',
        'monthsLoading',
        'monthsFirstFetch',
        'monthsInfo',
        'monthsMaxPage',
        'monthsPage',
      ])
    },
    methods: {
      ...mapActions([
        'fetchMonths'
      ]),
      onBackClick() {
        RyadomApp.backToApp();
      },
      onMonthClick(v) {
        this.$router.push({
          name: 'month-full',
          params: { date: v.dateNumber, textedDate: v.date }
        });
      },
      fetchMonthsForScroll() {
        if (this.monthsPage >= this.monthsMaxPage) {
          return;
        }
        this.fetchMonths()
          .catch(err => this.toast.show(err.message));
      },
    }
  };
</script>

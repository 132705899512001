import axios from 'axios';
import { stringify } from 'qs';
import { v4 as uuid } from 'uuid';

import { transformError } from '@shared/utils/transformError';
import { createStorage } from '@shared/services/storage';
import { parseToken } from '@shared/utils/parseToken';

import { courier } from './modules/courier';
import { auth } from './modules/auth';

const storage = createStorage(window.localStorage);
const client = axios.create({
  baseURL: process.env.CHOCO_GATEWAY,
  paramsSerializer: stringify,
  transformRequest: [
    data => stringify(data)
  ]
});

client.context = {
  accessToken: storage.get('auth::token'),
  refreshToken: storage.get('auth::refresh-token'),
  expireIn: storage.get('auth::expire-in'),
  xRole: 'courier'
};

client.interceptors.request.use(
  config => {
    if (client.context.accessToken) {
      const xUser = client.context.accessToken
        ? parseToken(client.context.accessToken).sub
        : 0;
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${client.context.accessToken}`;
      config.headers['X-Idempotency-key'] = uuid();
      config.headers['X-Role'] = client.context.xRole;
      config.headers['X-User'] = xUser;
    }

    return config;
  },
  err => Promise.reject(err)
);

client.interceptors.response.use(
  res => {
    return res.data.error_code !== 0
      ? Promise.reject(transformError(res.data))
      : res;
  },
  err => {
    return Promise.reject(transformError(err));
  }
);

export const api = {
  getContext() {
    return { ...client.context };
  },
  courier: courier(client),
  auth: auth(client, storage)
};

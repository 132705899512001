import Vue from 'vue';
import VueRouter from 'vue-router';

import MainView from '@views/main/view.vue';
import MonthFullView from '@views/month-full/view.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainView,
  },
  {
    path: '/month-full/:textedDate/:date',
    name: 'month-full',
    component: MonthFullView,
    props: true
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

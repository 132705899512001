<template>
  <transition name="slide-up" appear>
    <div
      class="
        max-w-full py-3 px-6
        text-white text-sm leading-tight text-center
        rounded-md pointer-events-auto duration-300
      "
      style="background: rgba(0, 0, 0, 0.6);"
      v-on="$listeners"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'UiToast'
  };
</script>

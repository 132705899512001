const getDay = date => {
  return date.split('-')[2];
};

export function filterDays(old, days) {
  days.forEach(v => {
    const day = getDay(v.dateNumber);
    if (old.hasOwnProperty(day)) {
      old[day].items.push(v);
    } else {
      old[day] = {
        date: v.date,
        items: []
      };
      old[day].items.push(v);
    }
  });
  return old;
}

<template>
  <div>
    <slot></slot>
    <div ref="infiniteScroll" class="h-1"></div>
  </div>
</template>

<script>

  const options = {
    root: null,
    threshold: 0
  };

  function isInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0
      && bounding.left >= 0
      && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  export default {
    name: 'UiInfiniteScroll',
    props: {
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        observer: null,
        isIntersecting: false
      };
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll);

      if (this.observer !== null) {
        this.observer.disconnect();
      }
    },
    mounted() {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver(this.callback, options);
        this.observer.observe(this.$refs.infiniteScroll);
      } else {
        window.addEventListener('scroll', this.onScroll);
      }
    },
    methods: {
      callback(entries) {
        entries.forEach(entry => {
          if (entry && entry.isIntersecting) {
            this.$emit('callback');
          }
        });
      },
      onScroll() {
        const isIntersecting = isInViewport(this.$refs.infiniteScroll);

        if (isIntersecting && !this.isIntersecting) {
          this.$emit('callback');
        }

        this.isIntersecting = isIntersecting;
      }
    }
  };
</script>

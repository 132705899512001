function getMessage(err) {
  if (err.code === 'ECONNABORTED') {
    return 'Превышено время ожидания ответа';
  }

  if (err.message === 'Network Error') {
    return 'Ошибка сети';
  }

  if (err.error_code === 500) {
    return 'Ошибка сервера';
  }

  if (err.error_code === 401) {
    return 'Авторизуйтесь в Рахмет';
  }

  return err.message || 'Произошла ошибка';
}

export const transformError = err => ({
  error_code: -1,
  message: getMessage(err),
  data: null
});

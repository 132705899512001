<template>
  <div class="bg-white shadow-lg rounded-xl overflow-hidden p-4" v-on="$listeners">
    <h4 class="font-bold text-xl mb-4">{{ capitalizedMonthName }}</h4>
    <ul class="list">
      <li>
        <p>Всего смен</p>
        <p>{{ month.selectedShifts }}</p>
      </li>
      <li>
        <p>Всего часов</p>
        <p>{{ month.totalWorkedHours }}</p>
      </li>
      <li>
        <p>Помощь на складе</p>
        <p>{{ month.warehouseActivityHours }}</p>
      </li>
      <li>
        <p>На перерыве</p>
        <p>{{ month.brokenHours }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'MonthBlock',
    props: {
      month: {
        type: Object,
        required: true
      }
    },
    computed: {
      capitalizedMonthName() {
        return this.month.date.charAt(0).toUpperCase() + this.month.date.slice(1);
      }
    }
  };
</script>

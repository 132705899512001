export function auth(client, storage) {
  return {
    validateToken() {
      return new Promise(resolve => {
        if (client.context.accessToken === undefined) {
          resolve(false);
          return;
        }

        client.post('/gateway/oauth/token/validate')
          .then(response => {
            /* eslint-disable-next-line camelcase */
            const { error_code: code, status } = response.data;
            resolve(code === 0 && status === 'success');
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    getTokenByTrackId(trackId) {
      return client.post('/auth/token', {
        client_id: process.env.CHOCO_GATEWAY_CLIENT_ID,
        grant_type: 'authorization_track_id',
        track_id: trackId
      }).then(res => {
        const {
          refresh_token: refreshToken,
          token: accessToken,
          expire_in: expireIn
        } = res.data.data;

        Object.assign(client.context, {
          accessToken, refreshToken, expireIn
        });

        storage.set('auth::token', client.context.accessToken);
        storage.set('auth::refresh-token', client.context.refreshToken);
        storage.set('auth::expire-in', client.context.expireIn);
      });
    }
  };
}

import Vue from 'vue';
import { ToastApp } from '@ui/UiToast';
import App from './App.vue';
import router from './router';
import store from './store';

import './assets/style/main.css';

Vue.config.productionTip = false;

ToastApp.mount();

new Vue({
  router,
  store,
  provide() {
    return {
      toast: ToastApp.toast
    };
  },
  render: h => h(App),
}).$mount('#app');

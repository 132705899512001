<template>
  <router-view />
</template>

<script>
  import { mapActions } from 'vuex';
  import { api } from '@shared/services/api';

  export default {
    name: 'App',
    created() {
      this.init({
        trackId: this.$route.query.track_id
      });
    },
    methods: {
      ...mapActions([
        'fetchMonths',
        'fetchInfo'
      ]),
      init({ trackId }) {
        if (trackId !== undefined) {
          this.authWithTrackId(trackId);
          return;
        }

        if (api.getContext().accessToken !== null && api.getContext().accessToken !== undefined) {
          this.authWithCachedToken();
        }
      },
      authWithCachedToken() {
        return api.auth.validateToken()
          .then(valid => {
            if (!valid) {
              Promise.reject();
            }
          });
      },
      authWithTrackId(trackId) {
        api.auth.getTokenByTrackId(trackId)
          .then(() => this.fetchInfo())
          .then(() => this.fetchMonths());
      },
    }
  };
</script>

export function courier(client) {
  return {
    getInformation() {
      return client.get('/courier/v1/informational?type=salary&is_active=1')
        .then(response => response.data);
    },
    getShiftInfo(payload) {
      const { page, limit = 15, date } = payload;

      let query = `?limit=${limit}`;

      if (page) { query += `&page=${page}`; }
      if (date) { query += `&date=${date}`; }

      return client.get(`/courier/v2/employee/report${query}`)
        .then(response => response.data);
    }
  };
}

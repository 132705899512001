<template>
  <div
    class="flex items-center w-full flex px-4
    bg-gray-200 leading-none z-20 h-14 fixed top-0 left-0"
  >
    <button
      class="inline active:opacity-50 focus:outline-none text-gray-600"
      type="button"
      @click="$emit('back')"
    >
      <svg
        class="fill-current"
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 7H3.83l5.59-5.59L8 0 0 8l8 8 1.41-1.41L3.83 9H16V7z" />
      </svg>
    </button>
    <div class="w-full flex flex-col text-center font-bold text-xl">
      <slot></slot>
    </div>
    <button
      class="inline active:opacity-50 focus:outline-none text-gray-600"
      type="button"
      @click="onClose"
    >
      <svg
        class="fill-current"
        width="16"
        height="16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2"
          y1="2"
          x2="14"
          y2="14"
          stroke="#4A4A4A"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <line
          x1="14"
          y1="2"
          x2="2"
          y2="14"
          stroke="#4A4A4A"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </button>
  </div>
</template>

<script>
  import { RyadomApp } from '@shared/RyadomApp';

  export default {
    name: 'Toolbar',
    methods: {
      onClose() {
        RyadomApp.backToApp();
      }
    }
  };
</script>

<template>
  <MainLayout>
    <Toolbar slot="toolbar" @back="onBackClick">{{ capitalizedMonthName }}</Toolbar>
    <Loader v-if="loading" class="transition duration-50" />
    <p v-else-if="Object.keys(days).length === 0" class="text-center">Упс, пусто</p>
    <div v-else class="p-4">
      <Day
        v-for="v in daysFromEnd"
        :key="v.date"
        :shift="v"
        class="mb-4"
      />
    </div>
  </MainLayout>
</template>

<script>
  import { api } from '@/shared/services/api';
  import Loader from '@ui/Loader.vue';
  import MainLayout from '@layout/MainLayout.vue';
  import Toolbar from '@layout/Toolbar.vue';

  import Day from './components/Day.vue';
  import { filterDays } from './utils/merge-days-by-day';

  export default {
    name: 'MonthFullView',
    components: {
      MainLayout,
      Toolbar,
      Day,
      Loader
    },
    inject: ['toast'],
    data() {
      return {
        loading: true,
        page: 1,
        days: {}
      };
    },
    computed: {
      capitalizedMonthName() {
        return this.$route.params.textedDate.charAt(0).toUpperCase()
          + this.$route.params.textedDate.slice(1);
      },
      daysFromEnd() {
        const arr = [];
        Object.values(this.days).forEach(v => arr.unshift(v));
        return arr;
      }
    },
    created() {
      this.fetchMonth();
    },
    methods: {
      onBackClick() {
        this.$router.go(-1);
      },
      fetchMonth() {
        api.courier.getShiftInfo({
          page: this.page,
          date: this.$route.params.date,
          limit: 100
        })
          .then(res => this.days = filterDays(this.days, res.data.items))
          .catch(err => this.toast.show(err.message))
          .finally(() => this.loading = false);
      }
    }
  };
</script>

<template>
  <div class="text-black bg-white rounded-xl shadow-lg overflow-hidden p-4">
    <h3 class="font-bold text-xl mb-2">{{ shift.date }}</h3>
    <ul
      v-for="(v, i) in shift.items"
      :key="`shift_${i}`"
      class="list mb-4"
    >
      <li>
        <p class="font-bold">Смена</p>
        <p class="font-bold">{{ v.shiftStartedAt }} - {{ v.shiftEndAt }}</p>
      </li>
      <li>
        <p>Всего</p>
        <p>{{ v.totalWorkedHours }}</p>
      </li>
      <li>
        <p>Перерыв</p>
        <p>{{ v.brokenHours }}</p>
      </li>
      <li>
        <p>Помощь по складу</p>
        <p>{{ v.warehouseActivityHours }}</p>
      </li>
      <li>
        <p>Непринятые заказы</p>
        <p>{{ v.unacceptedOrders }}</p>
      </li>
      <li>
        <p>Принятые заказы</p>
        <p>{{ v.acceptedOrders }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'Day',
    props: {
      shift: {
        type: Object,
        required: true
      }
    }
  };
</script>

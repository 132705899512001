<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="bg-orange-400 p-4">
    <div class="flex flex-col leading-tight font-semibold" v-html="html"></div>
  </div>
</template>

<script>

  export default {
    component: 'InfoBlock',
    props: {
      html: {
        type: String,
        required: true
      }
    }
  };
</script>

<template>
  <div class="h-full flex flex-col text-black">
    <header class="z-footer">
      <slot name="toolbar"></slot>
    </header>
    <main
      class="flex-1 bg-gray-200 mt-14"
    >
      <slot></slot>
    </main>
  </div>
</template>

<script>
  export default {
    name: 'MainLayout'
  };
</script>

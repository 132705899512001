const getterPlaceholder = (key, fallback = null) => fallback;
const setterPlaceholder = () => false;

const storagePlaceholder = {
  parse: getterPlaceholder,
  get: getterPlaceholder,
  stringify: setterPlaceholder,
  set: setterPlaceholder,
  remove: () => {}
};

export function createStorage(webStorage) {
  if (!webStorage) {
    return storagePlaceholder;
  }

  function get(key, fallback = null) {
    const value = webStorage.getItem(key);

    return value !== null
      ? value
      : fallback;
  }

  function set(key, value) {
    try {
      webStorage.setItem(key, value);
      return true;
    } catch (error) {
      return false;
    }
  }

  function parse(key, fallback = null) {
    try {
      const value = JSON.parse(webStorage.getItem(key));

      return value !== null
        ? value
        : fallback;
    } catch (error) {
      return fallback;
    }
  }

  function stringify(key, value) {
    return set(key, JSON.stringify(value));
  }

  function remove(key) {
    webStorage.removeItem(key);
  }

  return {
    stringify,
    parse,
    get,
    set,
    remove
  };
}

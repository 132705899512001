import Vue from 'vue';
import UiToast from './UiToast.vue';

function createToastApp() {
  const state = Vue.observable({
    id: 0,
    toasts: []
  });

  return {
    toast: {
      show(content) {
        const { id } = state;

        state.id += 1;
        state.toasts.push({ id, content });

        setTimeout(() => {
          state.toasts = state.toasts.filter(x => x.id !== id);
        }, 3000);
      }
    },
    mount() {
      new Vue({
        data: state,
        render(h) {
          return h('div', {
            class: `
              px-4 xs:px-8
              z-toast fixed inset-0
              flex flex-col items-center justify-center
              space-y-2
              pointer-events-none
            `
          }, this.toasts.map(toast => h(UiToast, {
            on: {
              click: () => {
                this.toasts = this.toasts
                  .filter(x => x.id !== toast.id);
              }
            }
          }, [toast.content])));
        }
      }).$mount('#toast-app');
    }
  };
}

export const ToastApp = createToastApp();

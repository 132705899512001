import Vue from 'vue';
import Vuex from 'vuex';

import { api } from '@shared/services/api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    months: [],
    monthsPage: 0,
    monthsMaxPage: 1,
    monthsLoading: false,
    monthsFirstFetch: true,
    monthsInfo: ''
  },
  getters: {
    months(state) {
      return state.months;
    },
    monthsLoading(state) {
      return state.monthsLoading;
    },
    monthsPage(state) {
      return state.monthsPage;
    },
    monthsInfo(state) {
      return state.monthsInfo;
    },
    monthsMaxPage(state) {
      return state.monthsMaxPage;
    },
    monthsNextPage(state) {
      return state.monthsPage + 1;
    },
    monthsFirstFetch(state) {
      return state.monthsFirstFetch;
    }
  },
  mutations: {
    SET_MONTHS(state, value) {
      state.months = state.months.concat(value);
    },
    SET_MONTHS_LOADING(state, value) {
      state.monthsLoading = value;
    },
    SET_MONTHS_INFO(state, value) {
      state.monthsInfo = value;
    },
    SET_MONTHS_PAGE(state, value) {
      state.monthsPage = value;
    },
    SET_MONTHS_MAX_PAGE(state, value) {
      state.monthsMaxPage = value;
    },
    SET_MONTHS_FIRST_FETCH(state, value) {
      state.monthsFirstFetch = value;
    }
  },
  actions: {
    fetchMonths(ctx) {
      ctx.commit('SET_MONTHS_LOADING', true);
      return api.courier.getShiftInfo({
        page: ctx.getters.monthsNextPage,
        limit: 3
      })
        .then(res => {
          const { items, pagination } = res.data;
          ctx.commit('SET_MONTHS_PAGE', pagination.current_page);
          ctx.commit('SET_MONTHS_MAX_PAGE', pagination.total_pages);
          ctx.commit('SET_MONTHS', items);
          ctx.commit('SET_MONTHS_LOADING', false);
        })
        .finally(() => ctx.commit('SET_MONTHS_FIRST_FETCH', false));
    },
    fetchInfo(ctx) {
      return api.courier.getInformation()
        .then(res => ctx.commit('SET_MONTHS_INFO', res.data.name));
    }
  },
  modules: {
  },
});
